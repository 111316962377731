<script setup>
defineProps({
  topicValue: String,
  imgValue: String
})
</script>

<template>
  <header class="header-subpages">
    <RouterLink :to="'/' + topicValue" class="main-nav">
      <img :src="require(`@/assets/globals/svg/Icon_${imgValue}.svg`)" alt="" />
      <h1 class="sub-heading" value="topicValue">
        {{ topicValue }}
      </h1>
    </RouterLink>
    <a href="/" class="flex flex-v-center">
      <img
        alt="Memorinator logo zur Startseite"
        title="zur Startseite"
        class="header-logo"
        src="@/assets/logo.svg"
        width="300"
        height="80"
      />
    </a>
  </header>
</template>
<script>
export default {
  methods: {
    getImgValue(topic) {
      // const imageUrl = new URL(`/src/assets/globals/Icon_${topic}_DS.png`, import.meta.url).href
      // return imageUrl
     let images = require.context('@/assets/globals/', false, /\.png$/)
    return images('Icon_' + topic + ".png")
    }
  }
}
</script>
