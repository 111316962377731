<script setup>
import MainModal from '@/components/global/MainModal.vue'
</script>
<template>
  <div class="final-sentence flex wrap gap-1 w-100 my-2">
    <span class="" v-for="(el, index) in words">
      <p
        v-if="el.bedeutung"
        :class="'list-element list-element-hover main-word underline underline__' + el.color"
        :id="'modal-' + index"
        @click="openModal(index)"
      >
        {{ el.name }}
      </p>
      <p v-else class="list-element main-word">
        {{ el.name }}
      </p>
    </span>
  </div>
  <MainModal />
</template>
<script>
export default {
  props: ['words'],
  mounted() {
    if(localStorage.getItem('tooltip') == null) {
      const tooltip = document.createElement("span")
      const newContent = document.createTextNode("Berühre die unterstrichenen Wörter!");
      tooltip.appendChild(newContent)
      tooltip.classList.add("tooltip")
      document.getElementsByClassName("list-element-hover")[0].appendChild(tooltip)
      setTimeout( function() {
        tooltip.remove()
      }, 3000);
      localStorage.setItem('tooltip', true)
    }
  },
  methods: {
    openModal: function (index) {


      let modal = document.getElementById('meaning-modal')
      let header = document.getElementById('modal-header')
      let content = document.getElementById('modal-content')
      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      modal.classList.toggle('none')
      header.innerHTML = `<h3>"${this.words[index].name}"</h3>`
      content.innerHTML = `<p>${this.words[index].bedeutung}</p>`

      console.log(window.innerHeight + " " + JSON.stringify(document.getElementById('inner-modal').getBoundingClientRect().height))
    if(window.innerHeight >= (document.getElementById('inner-modal').getBoundingClientRect().height)) {
      document.getElementById('inner-modal').style.alignSelf = "center"
    } else {
      document.getElementById('inner-modal').style.alignSelf = "start"
    }
    }
  }
}
</script>
<style scoped lang="scss">
.final-sentence{
  margin-bottom: 100px;
}
.final-sentence span:first-of-type .list-element-hover {
  position: relative;
}

.underline {
  text-decoration: underline;
  text-decoration-thickness: 6px;
  text-underline-offset: 7px;
  &__pink {
    text-decoration-color: var(--pink);
  }
  &__yellow {
    text-decoration-color: var(--yellow);
  }
  &__orange {
    text-decoration-color: var(--orange);
  }
  &__turqoise {
    text-decoration-color: var(--turqoise);
  }
}
</style>
