<script setup></script>
<template>
  <section class="orange__bg py-2" aria-label="Hauptlinks">
    <div class="w-max flex wrap">
      <RouterLink class="white flex flex-v-center" to="/impressum"
        >Impressum</RouterLink
      >
      <div class="flex sub-menu sub-menu__social flex-v-center wrap">
        <a class="white" href="https://local-history.net" target="_blank"
          >local-history.net</a
        >
        <ul class="flex-v-center">
          <li>
            <a
              href="https://www.youtube.com/@ueberLEBENSWEGE"
              target="_blank"
              aria-label="Link zu der RAA auf Youtube"
              ><img
                src="@/assets/globals/svg/Social_youtube.svg"
                height="80"
                alt=""
              />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/ueber_lebenswege/"
              target="_blank"
              aria-label="Link zu dem Projekt Überlebenswege auf Instagram"
              ><img
                height="60"
                src="@/assets/globals/svg/Social_instagram.svg"
                alt=""
              />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
#app {
  position: relative;
}
.w-max {
  justify-content: space-between;
}
.sub-menu {
  column-gap: 3em;
}
</style>
