<template>
  <div id="info-modal" class="none">
    <div class="popup-text" id="info-inner-modal">
      <button
        @click="closeModal"
        class="close-btn"
        title="schließen"
        aria-label="Modal schließen"
      >
        <span></span>
        <span></span>
      </button>
      <div id="info-modal-content"></div>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    closeModal: function () {
      document.getElementsByTagName("html")[0].style.overflowY = "auto"
      document.getElementById('info-modal').classList.toggle('none')
    }
  }
}
</script>
