<script setup>
import HeaderComplete from '../components/header/HeaderComplete.vue'
import TopicWords from '../components/words/TopicWords.vue'
</script>
<template>
  <div class="content-container" id="mainContent">
    <HeaderComplete topic-value="erkunden" img-value="auge"></HeaderComplete>
    <main class="centered-view">
      <TopicWords gameSelection="auge" />
    </main>
  </div>
</template>
<script>
export default {
  methods: {}
}
</script>
