<script setup>
import HeaderComplete from '../components/header/HeaderComplete.vue'
</script>
<template>
  <div class="content-container" id="mainContent">
    <HeaderComplete topic-value="erkunden" img-value="auge"></HeaderComplete>
    <main class="centered-view">
      <h1>Impressum</h1>
      <section class="py-2">
        <p>
          Rechtliche Informationen zum Betreiber dieser Website. Informationen
          zum Datenschutz finden Sie in der
          <a href="https://www.local-history-net.com" target="_blank"
            >Datenschutzerklärung</a
          >.
        </p>
        <p>Angaben gemäß § 5 TMG:</p>
        <p class="py-2">
          RAA – Demokratie und Bildung Mecklenburg-Vorpommern e. V.<br />
          Am Melzer See 1<br />
          17192 Waren (Müritz)
        </p>
        <p>Vertreten durch Herrn Christian Utpatel</p>
      </section>
      <section class="py-2">
        <h3>Verantwortlichkeit</h3>
        <p>
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br />
          Christian Utpatel<br />RAA – Demokratie und Bildung
          Mecklenburg-Vorpommern e. V.<br />Am Melzer See 1<br />17192 Waren
          (Müritz)<br /><br />Telefon: + 49 (0) 3991 66 96 0<br />E-Mail:
          <a
            href="mailto:info@raa-mv.de?subject=Projekt%20%C3%BCberLEBENSWEGE%20Memorinator%20Archiv%20Ravensbr%C3%BCck"
            >info@raa-mv.de</a
          >
        </p>
      </section>
      <hr />
      <section class="py-2">
        <h4>Registereintrag</h4>
        <p>
          Eintragung im Vereinsregister.<br />
          Registergericht: Amtsgericht Neubrandenburg<br />
          Registernummer: 1879
        </p>
        <p>
          Projekt<br />überLEBENSWEGE | Lokale Spurensuche & digitale
          Erinnerungswerkstätten (2023-2024)
        </p>
      </section>
      <section class="py-2">
        <h5>Idee</h5>
        <p>Angi Meyer & monströös</p>
        <h5>Konzeption, Gestaltung und Produktionsleitung</h5>
        <p>
          monströös (<a href="https://monstroos.com/" target="_blank"
            >www.monstroos.com</a
          >)
        </p>
        <h5>Webentwicklung</h5>
        <p>
          Olga Maus (<a href="https://o-maus.com/" target="_blank"
            >www.o-maus.com</a
          >)
        </p>
        <h5>Wissenschaftliche Leitung</h5>
        <p>Angi Meyer</p>
        <h5>Redaktion</h5>
        <p>
          Angi Meyer, Constanze Jaiser, Anja Schmidt, Anton Zscherpe & monströös
        </p>
        <h5>Dank an</h5>
        <p>
          Ingrid Bettwieser und allen Teilnehmenden des Künstler*innenSymposiums
          2023
        </p>
      </section>
      <hr />
      <section class="my-2">
        <h2 class="orange">Rechtliche Hinweise (Disclaimer)</h2>
        <p>
          Bitte beachten Sie die wichtigen rechtlichen Hinweise zu den Inhalten
          und zur Verfügbarkeit dieser Website, zum Urheberrecht und zu externen
          Links.
        </p>
        <h4>Inhalte dieser Website</h4>
        <p>
          Die Inhalte unserer Website werden mit größtmöglicher Sorgfalt
          erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der
          Inhalte können wir jedoch keine Gewähr übernehmen.
        </p>
        <h4>Verfügbarkeit der Website</h4>
        <p>
          Wir bemühen uns, den Dienst möglichst unterbrechungsfrei zum Abruf
          anzubieten. Auch bei aller Sorgfalt können aber Ausfallzeiten nicht
          ausgeschlossen werden. Wir behalten uns das Recht vor, unser Angebot
          jederzeit zu ändern oder einzustellen. Für durch nicht fehlerfrei
          angelegte Dateien oder nicht fehlerfrei strukturierte Formate,
          bedingte Unterbrechungen oder anderweitige Störungen können wir keine
          Gewähr übernehmen.
        </p>
        <h4>Hinweis zum Urheberrecht</h4>
        <p>
          Alle Inhalte und Strukturen dieser Website sind urheber- und
          leistungsschutzrechtlich geschützt. Die Veröffentlichung im World Wide
          Web oder in sonstigen Diensten des Internets bedeutet noch keine
          Einverständniserklärung für eine anderweitige Nutzung durch Dritte.
          Jede vom deutschen Urheberrecht nicht zugelassene Verwertung bedarf
          der vorherigen schriftlichen Zustimmung der Seitenbetreiber. Wir
          erlauben und begrüßen ausdrücklich das Zitieren unserer Dokumente
          sowie das Setzen von Links auf unsere Website, solange kenntlich
          gemacht wird, dass es sich um Inhalte des Webauftritts des
          RAA-Projekts überLEBENSWEGE handelt und diese Inhalte nicht in
          Verbindung mit Inhalten Dritter gebracht werden, die den Interessen
          der RAA – Bildung und Demokratie Mecklenburg-Vorpommern e. V.
          widersprechen. Mehr Informationen zum Urheberrecht finden sie unter
          <a href="" target="_blank">Nutzungsbedingungen</a>.
        </p>
        <h4>Hinweis zur Verwendung externer Links</h4>
        <p>
          Die RAA – Bildung und Demokratie Mecklenburg-Vorpommern e. V. ist als
          Inhaltsanbieterin nach § 7 Abs. 1 Telemediengesetz für die eigenen
          Inhalte, die sie zur Nutzung bereithält, nach den allgemeinen Gesetzen
          verantwortlich. Von diesen eigenen Inhalten sind Querverweise (Links)
          auf die von anderen Anbietern bereitgehaltenen Inhalte zu
          unterscheiden. Durch den Querverweis halten wir insofern fremde
          Inhalte zur Nutzung bereit, die als „Link“ gekennzeichnet sind.
        </p>
        <p>
          Bei Links handelt es sich stets um dynamische Verweisungen. Wir haben
          bei der erstmaligen Verknüpfung zwar den fremden Inhalt daraufhin
          überprüft, ob durch ihn eine mögliche zivilrechtliche oder
          strafrechtliche Verantwortlichkeit ausgelöst wird. Wir überprüfen aber
          die Inhalte, auf die wir in unserem Angebot verweisen, nicht ständig
          auf Veränderungen, die eine Verantwortlichkeit neu begründen könnten.
          Wenn wir feststellen oder von anderen darauf hingewiesen werden, dass
          ein konkretes Angebot, zu dem wir einen Link bereitgestellt haben,
          eine zivil- oder strafrechtliche Verantwortlichkeit auslöst, werden
          wir den Verweis auf dieses Angebot aufheben.
        </p>
        <p>
          Gefördert aus den Mittel der Stiftung Erinnerung, Verantwortung,
          Zukunft (EVZ) und dem Bundesministerium der Finanzen / Ein Projekt der
          Bildungsagenda NS-Unrecht
        </p>
      </section>
    </main>
  </div>
</template>
<script>
export default {
    mounted() {
        window.scrollTo({top: 0, behavior: "smooth"})
    }
}
</script>
<style lang="scss" scoped>
.centered-view {
    margin-top: 5em;
    text-align: left;
}
h1 {
  font-size: 2.2rem;
}
h2{
  font-size: 26px;
  font-weight: 600;
  margin-bottom: .6em;

}
h3{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: .6em;

}
h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: .4em;
}
p, h5 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5 !important;
}
p{
  margin-bottom: .4em;
}
a {
  font-size: 1rem;
}
</style>
