import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import GrabenView from "../views/GrabenView.vue";
import ErinnernView from "../views/ErinnernView.vue";
import ErkundenView from "../views/ErkundenView.vue";
import MemoriView from "../views/MemoriView.vue";
import SourceView from "../views/SourceView.vue";
import TestView from "../views/TestView.vue";
import NotFound from "../views/NotFound.vue";
import ImpressumView from "../views/ImpressumView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/graben",
    name: "graben",
    component: GrabenView,
  },
  {
    path: "/impressum",
    name: "impressum",
    component: ImpressumView,
  },
  {
    path: "/quellen",
    name: "quellen",
    component: SourceView,
  },
  {
    path: "/erkunden",
    name: "erkunden",
    component: ErkundenView,
  },
  {
    path: "/test",
    name: "test",
    component: TestView,
  },
  {
    path: "/erinnern",
    name: "erinnern",
    component: ErinnernView,
  },
  {
    path: "/memori",
    name: "memori",
    component: MemoriView,
  },
  {
    path: "/:notFound",
    name: "notFound",
    component: NotFound,
  },
  // default redirect to home page
  // { path: "/:pathMatch(.*)*", name: "home", redirect: "/" },
  // Your other routes
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (JSON.parse(localStorage.getItem("imgDownload")) == false) {
    if (from.name == "memori") {
      console.log(localStorage.getItem("imgDownload"));
      const answer = window.confirm(
        "Wenn du die Seite verlässt, wirst du das generierte Memori verlieren. Willst du es vorher herunterladen?"
      );
      if (answer) {
        next();
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        next(false);
      }
      localStorage.setItem("imgDownload", true);
    } else {
      next();
    }
  } else next();
});

export default router;
