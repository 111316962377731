<template>
  <div style="text-align: left"></div>
  <RouterLink
    class="main-btn main-btn__right w-auto flex flex-v-center"
    @click="generateMemori()"
    to="/memori"
  >
    Erstelle ein Memori
  </RouterLink>
</template>
<script>
export default {
  data() {
    return {
      data : "",
      newData: "",
      uniqueDirSrcPairs: ""
    }
  },
  props: ["memoriList"],
  mounted() {
    let uniqueDirSrcPairs = [];
    let dirsSet = new Set();
    let srcSet = new Set();

    for (let item of this.memoriList[0]) {
        // Check if "img" property exists and is an array
        if (item.bild || Array.isArray(item.bild)) {
            // console.log(`No "bild" property found for name: ${item.name}`);

        let dirFound = false;
        for (let imgItem of item.bild) {
            if (!dirsSet.has(imgItem.dir)) {
                for (let src of imgItem.src) {
                    if (!srcSet.has(src)) {
                        dirsSet.add(imgItem.dir);
                        srcSet.add(src);
                        uniqueDirSrcPairs.push({"dir": imgItem.dir, "src": src});
                        dirFound = true;
                        break; // Ensure we only get one dir and one src per object
                    }
                }
                if (dirFound) break; // Move to the next top-level object after finding one unique dir and src
            }
        }
        if (!dirFound) {
            for (let imgItem of item.bild) {
                for (let src of imgItem.src) {
                    if (!srcSet.has(src)) {
                        srcSet.add(src);
                        uniqueDirSrcPairs.push({"dir": imgItem.dir, "src": src});
                        dirFound = true;
                        break; // Ensure we only get one src per object
                    }
                }
                if (dirFound) break; // Move to the next top-level object after finding one src
            }
        }
      }
    }
    this.uniqueDirSrcPairs = uniqueDirSrcPairs
  },
  methods: {
    generateMemori: function() {
      localStorage.setItem("allWords", JSON.stringify(this.memoriList[0]))
      localStorage.setItem('myWords', JSON.stringify(this.uniqueDirSrcPairs))
      localStorage.setItem('myTitle', this.memoriList[1])
      localStorage.removeItem('imgDownload')
    }
  }
}
</script>
