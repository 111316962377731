<template>
  <div class="content-container" id="mainContent">
    <section>
      <h1>Graben</h1>
      <div v-for="data in hand">
        <h3>{{data.name}}</h3>
        <div class="py-2" v-for="folder in data.bild">
          <div class="orange" v-for="img in folder.src">
            {{ img }}
            <div v-if="folder.dir !== 'Text'">
              <img
                :src="require(`@/assets/image/still/${folder.dir}/${img}.png`)"
                alt=""
              />
              <p class="green" v-if="writeSynopsis(img)">
                {{ writeSynopsis(img) }}
              </p>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <h1>Erkunden</h1>
      <div v-for="data in auge">
        <h3>{{data.name}}</h3>
        <div class="py-2" v-for="folder in data.bild">
          <div class="orange" v-for="img in folder.src">
            {{ img }}
            <div v-if="folder.dir !== 'Text'">
              <img
                :src="require(`@/assets/image/still/${folder.dir}/${img}.png`)"
                alt=""
              />
              <p class="green" v-if="writeSynopsis(img)">
                {{ writeSynopsis(img) }}
              </p>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <h1>Erinnern</h1>
      <div v-for="data in herz">
        <h3>{{data.name}}</h3>
        <div class="py-2" v-for="folder in data.bild">
          <div class="orange" v-for="img in folder.src">
            {{ img }}
            <div v-if="folder.dir !== 'Text'">
              <img
                :src="require(`@/assets/image/still/${folder.dir}/${img}.png`)"
                alt=""
              />
              <p class="green" v-if="writeSynopsis(img)">
                {{ writeSynopsis(img) }}
              </p>
            </div>
          </div>
        </div>
        <hr />
      </div>
    </section>
  </div>
</template>
<script>
import synopsis from '../../synopsis.json'
import auge from '../../auge.json'
import herz from '../../herz.json'
import hand from '../../hand.json'
export default {
  data() {
    return {
      testdata: '',
      herz: herz,
      auge: auge,
      hand: hand
    }
  },
  mounted() {
    this.testdata = JSON.parse(JSON.stringify(synopsis))
  },
  methods: {
    filteredImg(dir, pic) {
      const imageUrl = new URL(`/src/assets/image/still/${dir}/${pic}.png`, import.meta.url).href
      return imageUrl
    },
    writeSynopsis(name) {
      let test = []
      synopsis.map((el) => {
        if (el[0] == name) {
          test.push(el[1])
        }
      })
      // console.log(test)
      return test[0]
    }
  }
}
</script>
<style scoped lang="scss">
img {
  max-width: 100px;
}
p {
  font-size: 1.2rem;
}
</style>
