<template>
  <section>
    <div class="trigger-box">
      <header>
        <h2 class="">Triggerwarnung</h2>
      </header>
      <p class="">
        Wir empfehlen den MEMORInator ab einem Alter von 12 Jahren zu nutzen.
        Einzelne Themen können schwer emotional zu verarbeiten oder belastend
        sein.
      </p>
    </div>
    <div class="w-100 start-btn">
      <button title="weiter" v-on:click="closeInfo">
        <img
          alt="Triggerwarnung gelesen"
          class="backlay"
          height="120"
          width="120"
          src="@/assets/globals/svg/Button_Check.svg"
        />
      </button>
    </div>
    <p></p>
  </section>
</template>
<script>
export default {
  methods: {
    closeInfo: function () {
      this.$emit('closeInfo', false)
    }
  }
}
</script>
<style></style>
