<template>
  <div v-if="!isActive" class="w-100" id="wordCloudElement">
    <div class="h-100 draggable-list" id="draggable-list">
      <draggable
        class="list-group flex flex-h-center flex-v-center w-100 h-100 wrap gap-1"
        :list="mainWords"
        @change="log"
        group="people"
        :disabled="!enabled"
        itemKey="name"
      >
        <template #item="{ element }">
          <p
            class="list-group-item word-cloud main-word p-1"
            :id="fillBlanks(element.name)"
          >
            {{ element.name }}
          </p>
        </template>
      </draggable>
      <draggable
        class="list-group flex flex-h-center flex-v-center w-100 wrap gap-1"
        :list="extraWords"
        :disabled="!enabled"
        group="people"
        itemKey="name"
      >
        <template #item="{ element }">
          <p class="list-group-item word-cloud extra-word">
            {{ element.name }}
          </p>
        </template>
      </draggable>
    </div>
    <div class="wordcloud-btn flex flex-end my-1">
      <button
        class="img-btn"
        :disabled="finalWords.length == 0"
        @click="poplast"
        title="Worte löschen"
        aria-label="Alle Worte löschen"
      >
        <img
          src="@/assets/globals/svg/Button_Loeschen.svg"
          class="start-icon"
          alt=""
        />
      </button>
      <button
        class="img-btn"
        :disabled="finalWords.length <= 1"
        @click="shuffleWords"
        aria-label="Worte neu anordnen"
        title="Worte neu anordnen"
      >
        <img
          src="@/assets/globals/svg/Button_Refresh.svg"
          class="start-icon"
          alt=""
        />
      </button>
      <button
        class="img-btn"
        @click="buildwords"
        aria-label="Ok"
        title="Weiter"
        :disabled="countFinalWords <= 3"
      >
        <img
          src="@/assets/globals/svg/Button_OK.svg"
          class="start-icon"
          alt=""
        />
      </button>
    </div>
    <p v-if="!enabled">Maximale Zahl an Worten erreicht</p>
    <div class="flex draggable-result">
      <IconClickBack
        id="backToStart"
        v-if="finalWords.length <= 0"
        :whereTo="['start', 'zurück']"
      />
      <DraggableResult :resultWords="finalWords" @change="log" />
    </div>
  </div>
  <div v-else>
    <FinalSentence :words="finalWords" />
    <div class="choice-btn flex flex-v-center w-100 wrap">
      <FinalSentenceOptions @valueToggleView="disableView" />
      <BuildMemori :memoriList="[finalWords, title]" />
    </div>
  </div>
</template>
<script>
import FinalSentence from '@/components/words/FinalSentence.vue'
import FinalSentenceOptions from '@/components/words/FinalSentenceOptions.vue'
import BuildMemori from '@/components/words/BuildMemori.vue'
import DraggableResult from '@/components/words/DraggableResult.vue'
import IconClickBack from '@/components/icons/IconClickBack.vue'
import draggable from 'vuedraggable'
import auge from '../../../auge.json'
import herz from '../../../herz.json'
import hand from '../../../hand.json'
import extra from '../../../extra.json'

export default {
  name: 'two-lists',
  display: 'Two Lists',
  order: 1,
  components: {
    draggable,
    FinalSentence,
    FinalSentenceOptions,
    BuildMemori,
    DraggableResult,
    IconClickBack
  },
  props: ['gameSelection'],

  data() {
    return {
      allWordsList: '',
      mainWords: [],
      finalWords: [],
      extraWords: [],
      listExtra: extra,
      isActive: false,
      finalList: '',
      enabled: true,
      countFinalWords: 0,
      title: '',
      status: true,
      xAxis: [],
      yAxis: []
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "smooth"})
    this.status = false
    if (this.gameSelection == 'auge') {
      this.allWordsList = auge
      this.title = 'auge'
    } else if (this.gameSelection == 'herz') {
      this.allWordsList = herz
      this.title = 'herz'
    } else {
      this.allWordsList = hand
      this.title = 'hand'
    }
    // list with main words
    this.mainWords = this.allWordsList.slice(0)
    // list with some randomly selected extra words
    let test = this.listExtra.sort(() => 0.5 - Math.random())
    const selected = test.slice(0, 6)
    this.extraWords = selected.slice(0)

  },
  updated() {

    if(this.status == false) {
      const draggableList = document.getElementById('draggable-list');
    const ellipseWidth = draggableList.offsetWidth;
    const ellipseHeight = draggableList.offsetHeight;
    const MAX_ATTEMPTS = 1000; // Maximum attempts to place an element

    const placedElements = [];

    // Function to check if two rectangles intersect
    function doRectanglesIntersect(rect1, rect2) {
        return !(rect2.left > rect1.right ||
          rect2.right < rect1.left ||
          rect2.top > rect1.bottom ||
          rect2.bottom < rect1.top);
    }
    function getRandomFontSize(min, max) {
      return `${Math.floor(Math.random() * (max - min + 1)) + min}px`;
    }
    // Function to check if a point is within the ellipse
    function isPointInEllipse(x, y, w, h) {
        const dx = x - w / 2;
        const dy = y - h / 2;
        return (dx * dx) / (w * w / 4) + (dy * dy) / (h * h / 4) <= 1;
    }

    // Function to get bounding rectangle of an element with current position
    function getRect(element, x, y) {
        return {
            left: x,
            top: y,
            right: x + element.offsetWidth,
            bottom: y + element.offsetHeight
        };
    }

    // Function to place an element randomly within the ellipse without intersecting
    function placeElement(element) {
        let isPlaced = false;
        let attempts = 0;
        if(ellipseWidth <= 722) {
            element.style.fontSize = getRandomFontSize(15, 20);
          }
          else if(ellipseWidth <= 1000) {
            element.style.fontSize = getRandomFontSize(20, 25);
          }
          else element.style.fontSize = getRandomFontSize(25, 30);
        while (!isPlaced && attempts < MAX_ATTEMPTS) {
            attempts++;
            const elementWidth = element.offsetWidth;
            const elementHeight = element.offsetHeight;

            // Generate random positions within the ellipse bounding box
            const randomX = Math.random() * (ellipseWidth - elementWidth);
            const randomY = Math.random() * (ellipseHeight - elementHeight);

            // Check if the center of the element is within the ellipse
            const centerX = randomX + elementWidth / 2;
            const centerY = randomY + elementHeight / 2;

            if (isPointInEllipse(centerX, centerY, ellipseWidth, ellipseHeight)) {
                // Check for intersection with already placed elements
                const currentRect = getRect(element, randomX, randomY);

                let intersects = placedElements.some(otherRect => doRectanglesIntersect(currentRect, otherRect));

                if (!intersects) {
                    element.style.left = `${randomX}px`;
                    element.style.top = `${randomY}px`;
                    placedElements.push(currentRect);
                    isPlaced = true;
                }
            }
        }

        if (!isPlaced) {
          element.remove()
            console.warn(`Could not place element: ${element.innerText}`);
        }
    }

    // Function to place initial elements
    function placeElements() {
        const wordClouds = Array.from(document.querySelectorAll('.word-cloud'));
        wordClouds.forEach(element => placeElement(element));
    }

    // Set up MutationObserver to watch for new elements
    const observer = new MutationObserver(mutationsList => {
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach(node => {
                    if (node.classList && node.classList.contains('word-cloud')) {
                        placeElement(node);
                    }
                });
            }
        }
    });

    observer.observe(draggableList, { childList: true });

    placeElements();
    }
      this.status = true
    },

  methods: {
    fillBlanks: function (text) {
      text = text.replace(/\s/g, '_')
      return 'cli-' + text.toLowerCase()
    },
    getRandomFontSize: function(min, max) {
        return `${Math.floor(Math.random() * (max - min + 1)) + min}px`;
    },
    shuffleArray: function(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    },
    add: function(el) {
      console.log("add")
    },
    log: function (evt) {
      if(this.countFinalWords < 5) {

        this.enabled = true
        if (evt.added) {
          if (evt.added.element.bedeutung) {
            this.countFinalWords -= 1
            this.status = false
          }
        } else if (evt.removed) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          if (evt.removed.element.bild || evt.removed.element.bedeutung) {
            this.countFinalWords += 1
          }
        }
      }
      else {
        this.enabled = false
      }
    },
    poplast: function () {
      this.enabled = true
      window.scrollTo({ top: 0, behavior: 'smooth' })
      this.finalWords.forEach((word, index) => {
        if (word.bedeutung || word.bild) {
          this.mainWords.push(word)
        } else {
          this.extraWords.push(word)
        }
        this.finalWords = []
        this.countFinalWords = 0
        this.status = false
      })
    },
    shuffleWords: function () {
      if (this.finalWords.length >= 1) {
        this.finalWords = this.finalWords.sort((a, b) => 0.5 - Math.random())
      }
    },
    buildwords: function () {
      window.scrollTo({ top: 0, behavior: 'smooth' })

      this.isActive = this.isActive ? false : true
      for (let i = 0; i < this.finalWords.length; i++) {
        this.finalList += this.finalWords[i].name + ' '
      }

    },
    toggleView: function () {
      this.isActive = this.isActive ? false : true

    },
    disableView: function (value) {
      this.isActive = value
      this.status = false

    }
  }
}
</script>
<style lang="scss" scoped>
.choice-btn {
  justify-content: space-between;
  column-gap: 2em;
  row-gap: 1em;
}
</style>
