<script setup>
function goBack() {
  window.history.back()
}
defineProps({
  whereTo: Array
})
</script>
<template>
  <RouterLink
    value="whereTo"
    v-if="whereTo[0] == 'start'"
    class="main-btn main-btn__left flex flex-v-center"
    to="/"
    >{{ whereTo[1] }}</RouterLink
  >
  <button v-else class="main-btn main-btn__left flex flex-h-center flex-v-center" @click="goBack()">
    zurück
  </button>
</template>
