<script setup></script>
<template>
  <section class="white__bg black">
    <div class="w-max flex flex-h-stretch wrap flex-v-center">
      <div class="sub-menu__contact wrap">
        <p><strong>Kontakt:</strong></p>
        <p>
          <strong>
            RAA | Demokratie und Bildung Mecklenburg-Vorpommern e.V.<br />
            Am Melzer See 1<br />
            17192 Waren (Müritz)</strong
          >
          <br />
          <br />
          <strong>Telefon: <span>(+49) (0) 3991 66 96 0</span></strong>
        </p>
        <p>
          <strong>Email: </strong
          ><a href="mailto:info@raa-mv.de">info@raa-mv.de</a>
        </p>

        <div>
          <a href="https://www.raa-mv.de" target="_blank">www.raa-mv.de</a>
        </div>
      </div>
      <ul class="sub-menu__partners">
        <li class="flex flex-v-center">
          <a
            href="https://www.raa-mv.de/"
            target="_blank"
            aria-label="Link zu der Webseite der RAA"
          >
            <img
              width="200"
              height="150"
              class="p-1"
              src="@/assets/globals/partner/RAA_Logo.png"
              alt=""
          /></a>
        </li>
        <li>
          <a
            href="https://www.raa-mv.de/"
            target="_blank"
            aria-label="Link zu der RAA auf Instagram"
            ><img
              width="200"
              height="200"
              src="@/assets/globals/partner/ueberLEBENSWEGE_logo.png"
              alt=""
            />
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>
#app {
  position: relative;
}
.sub-menu {
  column-gap: 3em;
  &__contact {
    span {
      white-space: nowrap;
      font-weight: 600;
    }
  }
  &__partners {
    li {
      width: 50%;
      img {
        height: auto;
      }
    }
  }
}
</style>
