<script setup>
import MainModal from "@/components/global/MainModal.vue"
</script>
<template>
  <div class="w-100 result-grid my-2">
    <section class="result-grid__field" v-for="img in memoriData">
      <div v-if="img.dir == 'Text'" class="result-grid__text">
        <q>{{ img.src }}</q>
        <RouterLink to="/quellen#quellen-02">Quellen</RouterLink>
      </div>
      <div v-else>
        <div class="result-grid__data" v-if="img.dir && img.src">
          <p :id="'syn-' + img.dir">{{ writeSynopsis(img.src) }}</p>
          <RouterLink v-if="img.dir == 'Person'" to="/quellen#quellen-01"
            >Quellen</RouterLink
          >
          <RouterLink
            v-else-if="img.dir == 'Zeichnung' || img.dir == 'Gedicht' || img.dir == 'Alltagsobjekt'"
            to="/quellen#quellen-02"
            >Quelle</RouterLink
          >
          <RouterLink
            v-else-if="img.dir == 'Filmstills' || img.dir == 'Grußkarten_Geschenke' || img.dir == 'Karten_Grundrisse' || img.dir == 'Misc' || img.dir == 'Shapes'"
            to="/quellen#quellen-03"
            >Quellen</RouterLink
          >
        </div>
        <img
          v-if="img.dir && img.src"
          class="result-grid__img"
          :src="require(`@/assets/image/still/${img.dir}/${img.src}.png`)"
          @click="openModal(img)"
        />
      </div>
    </section>
  </div>
  <MainModal />
</template>
<script>
import synopsis from '../../../synopsis.json'

export default {
  data() {
    return {
      synopsisData: '',
      memoriData: "",
    }
  },
  mounted() {
    this.synopsisData = synopsis
    this.memoriData = JSON.parse(localStorage.getItem('myWords'))
  },
  methods: {
    openModal: function(img) {
      let modal = document.getElementById('meaning-modal')
      let content = document.getElementById('modal-content')
       modal.classList.toggle('none')

      document.getElementsByTagName("html")[0].style.overflowY = "hidden"
      if(content.hasChildNodes()) {
        content.firstChild.src = require(`@/assets/image/still/${img.dir}/${img.src}.png`)
      } else {
      let newimg = new Image();
      newimg.src =
      require(`@/assets/image/still/${img.dir}/${img.src}.png`)
            document.getElementById('modal-content').appendChild(newimg);
      }
    },
    fillBlanks: function (text) {
      text = text.replace(/\s/g, '_')
      return text.toLowerCase()
    },
    filteredImg(dir, pic) {
      const imageUrl = new URL(`/src/assets/image/still/${dir}/${pic}.png`, import.meta.url).href
      return imageUrl
    },
    writeSynopsis(name) {
      let test = []
      synopsis.map((el) => {
        if (el[0] == name) {
          test.push(el[1])
        }
      })
      return test[0]
    }
  }
}
</script>
<style scoped lang="scss">
.result-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  gap: 1em;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    @media screen and (min-width: 1024px) {
      grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    }
  }
  &__field {
    div {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      margin-bottom: 1em;
      height: 100%;
      @media screen and (min-width: 922px) {
        flex-direction: row-reverse;
      }
      img {
        object-fit: contain;
        object-position: center;
        max-width: calc(100vw - 2em);
      }
      p {
        max-width: calc(100vw - 2em);
      }
      img {
        max-width: 200px;
        cursor: pointer;
      @media screen and (min-width: 922px) {
        flex-direction: row-reverse;
        column-gap: 1em;
          max-width: 30%;
          object-fit: contain;
        }
      }
      .result-grid__data {
        display: block;
        padding-top: 1em;
        @media screen and (min-width: 922px) {
          padding-top: 0;
          padding-left: 1em;
        }
      }
    }

  }
  &__img {
    max-width: 100px;
  }
  &__text {
    flex-direction: column !important;
  }
}
a {
  color: var(--orange);
  font-weight: 700;
  font-size: 1.4rem;
}
</style>
