<script setup>
import MainMenu from '../components/global/MainMenu.vue'
import TriggerPopup from '../components/extra/TriggerPopup.vue'
</script>
<template>
  <div class="content-container" id="mainContent">
    <main class="centered-view">
      <h1 aria-label="Memorinator">
        <img
          alt="Memorinator Logo"
          class="logo"
          src="@/assets/logo.svg"
          width="125"
          height="125"
        />
      </h1>
      <MainMenu v-if="!showInfo" @closeInfo="handleMessage"></MainMenu>
      <TriggerPopup v-else @closeInfo="handleMessage"></TriggerPopup>
    </main>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showInfo: false
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "smooth"})
  },
  methods: {
    handleMessage: function (value) {
      window.scrollTo({top: 0, behavior: "smooth"})
      this.showInfo = value
    },
  }
}
</script>
<style lang="scss" scoped>
.centered-view {
  margin-top: -2em;
}
</style>
