<template>
  <div id="meaning-modal" class="none">
    <div class="popup-text" id="inner-modal">
      <button
        @click="closeModal"
        class="close-btn"
        title="schließen"
        aria-label="Modal schließen"
      >
        <span></span>
        <span></span>
      </button>
      <h3 id="modal-header"></h3>
      <div id="modal-content"></div>
    </div>
  </div>
</template>
<script>
export default {
  // mounted() {
    // document.getElementById("meaning-modal").addEventListener("click", () => {
    //   this.closeModal()
    // })

//  },
  methods: {
    closeModal: function () {
      console.log("modal schließen")
      document.getElementsByTagName("html")[0].style.overflowY = "auto"
      document.getElementById('meaning-modal').classList.toggle('none')
    }
  }
}
</script>
<style></style>
