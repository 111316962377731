<script setup>
import FooterTop from "./FooterTop.vue"
import FooterMiddle from "./FooterMiddle.vue"
import FooterBottom from "./FooterBottom.vue"
</script>
<template>
  <footer id="footer">
    <FooterTop />
    <FooterMiddle />
    <FooterBottom />
  </footer>
</template>

<script>
export default {


}
</script>

<style lang="scss" scoped></style>
