<template>
  <section class="orange__bg white py-2">
    <div class="w-max flex wrap w-100 flex-h-stretch py-2">
      <p>
        Ein Projekt der RAA | Demokratie und Bildung Mecklenburg-Vorpommern e.V.
      </p>
      <p>© 2024 RAA M-V</p>
    </div>
  </section>
  <section class="white__bg black">
    <div class="w-max">
      <ul class="sub-menu w-100">
        <li>
          <p class="footer-text footer-text__bold my-2">
            Das Projekt wird in der Bildungsagenda NS-Unrecht von der Stiftung
            Erinnerung, Verantwortung und Zukunft (EVZ) und dem
            Bundesministerium der Finanzen (BMF) gefördert.
          </p>
          <img
            src="@/assets/globals/partner/Bundesfinanz_evz_V02.png"
            aria-label="Ein Projekt der Bildungsagenda NS-Unrecht, gefördert durch das Bundesministerium für Finanzen aufgrund eines Beschlusses des Deutschen Bundestages und  und Stiftung Erinnerung Verantwortung Zukunft - EVZ"
          />
        </li>
        <li>
          <p class="footer-text my-2 py-2">In Kooperation mit:</p>
          <div class="flex coorperation my-2">
            <a
              href="https://www.ljr-brandenburg.de/"
              title="zur Startseite des Landesjugendrings Brandenburg - ljr"
              class="my-2"
              ><img
                class=""
                src="@/assets/globals/partner/landesjugendring_logo.png"
                alt="Logo Landesjugendring Brandenburg - ljr"
            /></a>
            <a
              class="my-2"
              href="https://www.ravensbrueck-sbg.de/"
              target="_blank"
              title="zur Startseite der Stiftung Brandenburgische Gedenkstätten - Mahn- und Gedenkstätte Ravensbrück"
            >
              <img
                class=""
                src="@/assets/globals/partner/Ravensbrueck_Logo.png"
                alt="Logo der Stiftung Brandenburgische Gedenkstätten - Mahn- und Gedenkstätte Ravensbrück"
            /></a>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>
<style lang="scss" scoped>

li {
  min-width: 50%;
  width: 100%;

  a {
  }
  p {
    max-width: max-content;
    font-family: "Source Code Pro" !important;
    font-weight: 400 !important;
  }
  .footer-text {
    font-size: 1rem;
    font-weight: 600;
    max-width: 600px;
    &__bold {
      font-weight: 800;
    }
  }
}
.sub-menu {
  flex-wrap: wrap;
  a {
    max-width: 50%;
    img {
      max-width: 100%;
      max-height: 100px;
    }
  }
@media screen and (min-width: 992px) {
  flex-wrap: nowrap;
  gap: 15vw;
  a {
    max-width: unset;
  }
  a img {
    max-height: 150px;
    width: auto;
    height: auto;
    max-width: 250px;
  }
}
}
.coorperation {
  flex-wrap: wrap;
  justify-content: space-evenly;
  @media screen and (min-width: 992px) {
    display: block;
  }
}
</style>
