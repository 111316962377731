<template>
  <button class="main-btn main-btn__left w-auto" @click="showWordCloud()">
    Wähle neue Worte aus
  </button>
</template>
<script>
export default {
  methods: {
    showWordCloud: function () {
      this.$emit('valueToggleView', false)
    }
  }
}
</script>
