<script setup>
import HeaderComplete from '../components/header/HeaderComplete.vue'
import DomImage from '@/components/final/DomImage.vue'
import IconClickBack from '@/components/icons/IconClickBack.vue'
</script>

<template>
  <div class="content-container" id="mainContent" v-if="status">
    <HeaderComplete
      :topic-value="memoriTopic"
      :img-value="memoriTitle"
    ></HeaderComplete>
    <main class="centered-view">
      <DomImage :topic-value="memoriTopic" />
      <div class="flex flex-h-stretch w-100 wrap">
        <IconClickBack :whereTo="['start', 'zum Anfang']" class="left" />
        <RouterLink
          class="main-btn main-btn__right"
          :to="'/' + randomLinkArray[randomLink].link"
          :title="'neues Memori im Bereich ' + randomLinkArray[randomLink].link"
          :aria-label="'neues Memori im Bereich ' + randomLinkArray[randomLink].link"
          ><img
            class="h-100"
            aria-label="Icon Auge"
            :src="require(`@/assets/globals/svg/Icon_${randomLinkArray[randomLink].img}_2.svg`)"
        /></RouterLink>
      </div>
    </main>
  </div>
  <div v-else class="content-container" id="mainContent">
    <div>
      <h1>Kein Memori gefunden</h1>
      <RouterLink
        to="/"
        class="main-btn main-btn__right flex flex-v-center"
        value="whereTo"
        >erstelle ein Memori</RouterLink
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      myWords: [],
      memoriTitle: '',
      memoriTopic: '',
      status: false,
      randomLinkArray: [
        {"link": "erkunden", "img": "auge"},
        {"link": "erinnern", "img": "herz"},
        {"link": "graben", "img": "hand"}
      ]
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.adjustMarginBottom);
  },
  methods: {
    adjustMarginBottom() {
      const footerHeight = document.getElementById("footer").offsetHeight;
      const mainContent = document.getElementById('mainContent');

      if (mainContent) {
        mainContent.style.marginBottom = `${footerHeight}px`;
      }
    }
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "smooth"})
    if (JSON.parse(localStorage.getItem('myWords'))) {
      this.status = true
      this.myWords = JSON.parse(localStorage.getItem('myWords'))
      let title = localStorage.getItem('myTitle')
      let topic
      if (title == 'auge') {
        topic = 'erkunden'
      } else if (title == 'herz') {
        topic = 'erinnern'
      } else {
        topic = 'graben'
      }
      this.memoriTitle = title
      this.memoriTopic = topic
    }
  },
  computed: {
    randomLink() {
      return Math.round(Math.random() * 2)
    }
  }
}
</script>
<style lang="scss" scoped>
.collage {
  min-height: 500px;
}
.popup-text {
  display: flex;
  align-items: center;
}
</style>
