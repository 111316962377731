<script setup>
import FinalSentence from './FinalSentence.vue'
import FinalGrid from './FinalGrid.vue'
import InfoModal from '../global/InfoModal.vue'
</script>
<template>
  <div class="w-100 collage flex collage-frame">
    <div id="spinner" class="img-frame">
      <div class="spinner-icon"></div>
    </div>
    <canvas
      width="1600"
      height="1600"
      class="img-frame"
      id="imgExport"
    ></canvas>
    <div class="flex flex-h-center gap-1 wrap">
      <FinalSentence />
      <div class="flex save-btn">
        <button
          class="img-btn bottom"
          aria-label="Download Bild"
          @click="downloadImg()"
        >
          <img src="@/assets/globals/svg/Button_Download.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
  <div class="w-100">
    <h3 class="sub-heading sub-heading__more">mehr {{ topicValue }}</h3>
    <FinalGrid :memoriData="[memoriData]" />
  </div>
  <InfoModal />
</template>
<script>
export default {
  data() {
    return {
      memoriData: "",
      newArr: [],
      title: "",
      downloadStatus: false
    }
  },
  props: ["topicValue"],
  mounted() {

    if(localStorage.getItem('imgDownload') == null) {
      localStorage.setItem('imgDownload', false)
    }
      if(localStorage.getItem('memoritip') == null) {
      let modal = document.getElementById('info-modal')
      let content = document.getElementById('info-modal-content')
      modal.classList.toggle('none')
      content.innerHTML = `<p>Du hast ein MEMORI entworfen! Jedes Fundstück hat eine Erzählung.<br>Lies weiter unten mehr dazu ... Teile deine Erfahrungspunkte!</p>`
      localStorage.setItem('memoritip', true)
    }

    this.memoriData = JSON.parse(localStorage.getItem('myWords'))
    this.title = localStorage.getItem('myTitle')

    const imgArray = this.memoriData;

    let bg_colors = ['#dfb1e3', '#e3ef98', '#9fdeae', '#ffa281'];
    const canvas = document.getElementById('imgExport');
    const context = canvas.getContext('2d');
    const spinner = document.getElementById('spinner'); // Spinner element
    const maxImages = 10;
    const imagesToDraw = imgArray.slice(0, maxImages);
    let maxSize = 1400;
    let minSize = 500;
    let tempText;

    const loadImage = (dir, src) => {
        if (dir !== "Text") {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = require(`@/assets/image/still/${dir}/${src}.png`);
                img.onload = () => resolve(img);
                img.onerror = (err) => reject(err);
            });
        } else {
            return src;
        }
    };

    const randomColor = () => {

        return bg_colors[Math.floor(Math.random() * bg_colors.length)];
    };

    const sortImages = (images) => {
        return images.sort((a, b) => {
            const order = [
                'Karten_Grundrisse', 'Filmstills', 'Gedicht', 'Zeichnung',
                'Shape', 'Person', 'Grußkarten_Geschenke', 'Text'
            ];
            const aIndex = order.findIndex(type => a.src.includes(type));
            const bIndex = order.findIndex(type => b.src.includes(type));
            return aIndex - bIndex;
        });
    };

    const isOverlapping = (x, y, size, positions) => {
        for (const pos of positions) {
            const dx = x - pos.x;
            const dy = y - pos.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            if (distance < (size + pos.size) / 2 * 0.5) { // 50% overlap
                return true;
            }
        }
        return false;
    };

    const calculatePoints = (count, canvasWidth, canvasHeight, sizes) => {
        const points = [];
        for (let i = 0; i < count; i++) {
            let x, y, attempts = 0;
            do {
                x = Math.random() * canvasWidth;
                y = Math.random() * canvasHeight;
                attempts++;
                if (attempts > 1000) break; // Avoid infinite loop
            } while (isOverlapping(x, y, sizes[i], points));
            points.push({ x, y, size: sizes[i] });
        }
        return points;
    };

    const drawImages = async () => {
        try {
            spinner.style.display = 'flex'; // Show spinner

            const sortedImages = sortImages(imagesToDraw);
            const images = await Promise.all(sortedImages.map(({ dir, src }) => loadImage(dir, src)));
            const sizes = images.map(() => Math.random() * (maxSize - minSize) + minSize);
            const points = calculatePoints(images.length, canvas.width, canvas.height, sizes);

            let color = randomColor()
            context.fillStyle = color;
            let index = bg_colors.indexOf(color);
            if (index !== -1) {
              bg_colors.splice(index, 1);
            }

            context.fillRect(0, 0, canvas.width, canvas.height);

            images.forEach((img, i) => {
                let { x, y, size } = points[i];
                const aspectRatio = img.width / img.height;
                let imgWidth, imgHeight;

                if (img.width > img.height) {
                    imgWidth = size;
                    imgHeight = size / aspectRatio;
                } else {
                    imgHeight = size;
                    imgWidth = size * aspectRatio;
                }

                if (typeof img !== "string") {
                    context.drawImage(img, x - imgWidth / 2, y - imgHeight / 2, imgWidth, imgHeight);
                } else {
                  if(x < 0) {
                    x = 0
                  } else if(x > 1300) {
                    x = 1300
                  }
                  if(y < 0) {
                    y = 0
                  } else if(y > 1500) {
                    y = 1500
                  }
                    tempText = {img: img, x: x, y: y};
                }

                if (minSize > 400) {
                    minSize -= 200;
                    maxSize -= 200;
                }
            });
        } catch (error) {
            console.error('Error loading images:', error);
        } finally {
            spinner.style.display = 'none'; // Hide spinner
        }
        if(tempText =! null) {
        const fontSize = Math.round(Math.random() * (99 - 60) + 60);
          context.font = `bold ${fontSize}px Source Code Pro`;
          context.fillStyle = randomColor();
          context.fillText(tempText.img, tempText.x, tempText.y);
        }
        const frame = new Image();
        frame.onload = () => {
            context.drawImage(frame, 0, 0, canvas.width, canvas.height);
        };
        frame.src = new URL(`/src/assets/globals/Rahmen_Memori.png`, import.meta.url).href;
    };

    drawImages();
  },

  methods: {
    filteredImg(dir, pic) {
      const imageUrl = new URL(`/src/assets/image/still/${dir}/${pic}.png`, import.meta.url).href
      return imageUrl
    },
    fillBlanks: function (text) {
      text = text.replace(/\s/g, '_')
      return text.toLowerCase().slice(1)
    },
    downloadImg: function () {
      localStorage.setItem('imgDownload', true)

      let nameArray = JSON.parse(localStorage.getItem('allWords') || '[]')
      let name = ""
      for (let i = 0; i < nameArray.length; i++) {
        name += " " + nameArray[i].name
      }
      const canvas = document.getElementById('imgExport');
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = this.fillBlanks(name);
      link.click();
      this.downloadStatus = true
    }
  }
}
</script>
<style scoped lang="scss">
// to-do
#spinner {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 2em auto;
  align-items: center;
  justify-content: center;
  z-index: 99;

.spinner-icon{
  background-image: url("../../assets/globals/svg/Icon_herz.svg");
  width: 150px;
  height: 150px;
  background-size: contain;
  background-repeat: no-repeat;
  animation: prixClipFix 2s infinite linear;
  background-position: center;
  }
}
@keyframes prixClipFix {
  0%   {transform: scale(1);}
  50% {transform: scale(1.5);}
  100% {transform: scale(1);}
}
.modal {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 30%;
  right: 0;
  margin: auto;
  width: 99%;
  height: 100%;
  max-width: 800px;
  max-height: 800px;
  background-color: #fff;
  z-index: 100;
  aspect-ratio: 1/1;
}
canvas {
  width: 100%;
  height: 100%;
}


.collage-frame {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  min-width: 100%;
  flex-direction: column;
  position: relative;
}
.img-frame {
  margin: 2em auto;
  aspect-ratio: 1/1 !important;
  position: relative;
  width: 100%;
  max-width: 600px;
}
.collageImg {
  position: absolute;
}
.save-btn {
  flex-direction: row-reverse;
}
</style>
