<template>
  <h1 class="finalSentence">
    {{ allText }}
  </h1>
</template>
<script>
export default {
  data() {
    return {
      allText: '',
      myWords: []
    }
  },
  mounted() {
    this.myWords = JSON.parse(localStorage.getItem('allWords') || '[]')

    for (let i = 0; i < this.myWords.length; i++) {
      this.allText += this.myWords[i].name + ' '
    }
  }
}
</script>
<style lang="scss" scoped>
.finalSentence {
  font-size: 1.2rem;
  align-self: center;
}
</style>
