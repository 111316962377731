<script setup>
defineProps({
  resultWords: Array
})
</script>
<template>
  <div class="draggable-result--container w-100">
    <draggable
      id="draggableResult"
      class="list-group gap-1 w-100 border wrap"
      :list="resultWords"
      group="people"
      itemKey="name"
    >
      <template #item="{ element }">
        <div
          :class="'list-group-item list-item--block final-word ' + element.color + '__bg'"
        >
          <p>
            {{ element.name }}
          </p>
        </div>
      </template>
    </draggable>
    <div class="temp-msg" v-if="resultWords.length == 0">
      Hier ist Platz für deine Poesie. Ziehe Worte in dieses Feld.
    </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  name: 'simple',
  display: 'Simple',
  order: 0,
  components: {
    draggable
  },
  data() {
    return {
      colors: ['orange', 'turqoise', 'pink', 'yellow']
    }
  },
  methods: {
    colorBackground: function () {
      let col = Math.round(Math.random() * (this.colors.length - 1))
      let list = Array.from(document.getElementsByClassName('final-word'))
      return list[list.length - 1].classList.add(this.colors[col] + '__bg')
    }
  }
}
</script>
<style scoped lang="scss">
.draggable-result {
  flex-direction: column-reverse;
  gap: 1em;
}
.draggable-result--container {
  position: relative;
  display: flex;
  align-items: center;

  .temp-msg {
    position: absolute;
    margin-left: 2em;
    text-align: left;
  }
}
</style>
