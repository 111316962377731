<script setup></script>
<template>
  <section class="w-100">
    <Transition>
      <span><h2 class="center">Wie möchtest du gedenken?</h2></span>
    </Transition>
    <p class="center my-2">
      Der MEMORInator verbindet Geschichten, Poesie, Fotos und Objekte aus dem
      Konzentrationslager. ERKUNDE die Geschichte, GRABE an den Orten
      Ravensbrück und Neubrandenburg. Und ERINNERE an die überLEBENSWEGE der
      inhaftierten Frauen. Entwirf dein persönliches MEMORI ...
    </p>
    <div class="three-col">
      <Transition>
        <RouterLink to="/erkunden" title="erkunden" aria-label="erkunden">
          <!-- <IconAuge /> -->
          <img
            class="start-icon backlay"
            src="@/assets/globals/svg/Icon_auge.svg"
            alt="Icon Auge"
          />
        </RouterLink>
      </Transition>
      <Transition>
        <RouterLink to="/erinnern" title="erinnern" aria-label="erinnern"
          ><img
            src="@/assets/globals/svg/Icon_herz.svg"
            class="start-icon backlay"
            alt="Icon Herz"
        /></RouterLink> </Transition
      ><Transition>
        <RouterLink to="/graben" title="graben" aria-label="graben"
          ><img
            src="@/assets/globals/svg/Icon_haende.svg"
            class="start-icon backlay"
            alt="Icon Hände" /></RouterLink
      ></Transition>
    </div>
    <Transition>
      <div class="w-100 start-btn">
        <button title="Triggerwarnung lesen" @click="openInfo">
          <img
            alt="Triggerwarnung lesen"
            class="backlay"
            height="120"
            width="120"
            src="@/assets/globals/svg/Button_Info.svg"
          />
        </button>
      </div>
    </Transition>
  </section>
</template>
<script>
export default {
  methods: {
    openInfo: function () {
      window.scrollTo({top: 0, behavior: "smooth"})
      this.$emit('closeInfo', true)
    }
  }
}
</script>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 1.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
