<script setup>
import HeaderComplete from '../components/header/HeaderComplete.vue'
</script>
<template>
  <div class="content-container" id="mainContent">
    <HeaderComplete topic-value="erkunden" img-value="auge"></HeaderComplete>
    <main class="flex centered-view flex-h-center">
      <h1 class="center">Seite nicht gefunden</h1>
      <RouterLink
        class="main-btn main-btn__right w-auto flex flex-v-center"
        to="/"
        >zurück zum Anfang</RouterLink
      >
    </main>
  </div>
</template>
<script>
export default {
    mounted() {
        window.scrollTo({top: 0, behavior: "smooth"})
    }
}
</script>
<style lang="scss" scoped>
.centered-view {
    margin-top: 5em;
    text-align: left;
}
h1 {
  font-size: 2.2rem;
}
h2{
  font-size: 26px;
  font-weight: 600;
  margin-bottom: .6em;

}
h3{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: .6em;

}
h4 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: .4em;
}
p, h5 {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5 !important;
}
p{
  margin-bottom: .4em;
}
a {
  font-size: 1rem;
}
</style>
